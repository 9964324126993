import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature4 from "components/features/TwoColSingleFeatureWithStats4.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
// import Founder from "images/founder.jpg";
import Director1 from "images/director1.jpg";
import Director2 from "images/director2.jpg";
import AboutUs from "images/aboutus.png";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
	return (
		<AnimationRevealPage>
			<Header />
			{/* <MainFeature1
				subheading={<Subheading>About Adhyan Digital</Subheading>}
				heading="We are a modern design agency."
				buttonRounded={false}
				primaryButtonText="See Portfolio"
				imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
			/> */}
			<MainFeature1
				subheading={<Subheading>About Adhyan Digital</Subheading>}
				heading="We aim to disrupt the education space."
				buttonRounded={false}
				primaryButtonText="Contact Us"
				imageSrc={AboutUs}
				textOnLeft={false}
			/>
			{/*<MainFeature4
				subheading={<Subheading>Founder & CEO</Subheading>}
				heading="Suvendu Chatterjee"
				description="He has experience of over 22 years as head of the membership in Corporate Sector. He has considerable execution experience especially in FMCG marketing and distribution having worked as national head for Reliance Agro Tech Limited. With his immense marketing experience, Mr. Chatterjee saw a lacuna in the education system and a huge opportunity in the field of education and in 2015, entered into the education business in the E-learning platform. The founders of the company had been toying with the idea since 2018 and in  accordance have developed the animated K-12 content for CBSE, and ICSE. Today we have come up with a platform which is all encompassing for school education where the school reaches the home of the student."
				buttonRounded={false}
				imageSrc={Founder}
				textOnLeft={false}
			/>*/}

			<MainFeature4
				subheading={<Subheading>Director</Subheading>}
				heading="Sanchayan Chatterjee"
				description="Mr. Sanchayan Chatterjee is the youngest, energetic, enthusiastic, and dynamic member of the board of directors. He is a B. Arch. And he is associated with in the year 2015."
				buttonRounded={false}
				imageSrc={Director1}
				textOnLeft={true}
			/>

			<MainFeature4
				subheading={<Subheading>Director</Subheading>}
				heading="Prosenjit Neogy"
				description="Mr. Prosenjit Neogy is an entrepreneur in the education sector. He has 22 years of experience in the education business. He has his computer training and smart classes, which he works with the schools. He is an alumni of St. Xavier's College and Jadavpur University, Kolkata."
				buttonRounded={false}
				imageSrc={Director2}
				textOnLeft={false}
			/>

			{/* <Features
				subheading={<Subheading>Our Values</Subheading>}
				heading="We follow these."
				description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
				cards={[
					{
						imageSrc: SupportIconImage,
						title: "24/7 Support",
						description:
							"Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
					},
					{
						imageSrc: ShieldIconImage,
						title: "Strong Teams",
						description:
							"Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
					},
					{
						imageSrc: CustomerLoveIconImage,
						title: "Customer Satisfaction",
						description:
							"Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
					},
				]}
				linkText=""
			/> */}
			<TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
			<Footer />
		</AnimationRevealPage>
	);
};
